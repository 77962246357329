.form-group.form-control-width-auto{
  .form-control{
    width: auto;
  }
}

.form-group-display-inline-block{
  >div{
    >div {
      display: inline-block;
    }
  }
}


.compulsory:after{
  content: ' *';
  color: $errorColor;
}
