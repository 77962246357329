#contact_page {
  footer {
    padding-top: 80px !important;
    .top_footer {
      background: white;
      color: unset;
    }
  }
  .titre-big {
    color: #FD8B75;
  }
  .confiance {
    display: none;
  }
}
