#services {
  padding-top: 60px;
  margin-bottom: 80px;
  .title {
    margin-bottom: 60px;
  }
  .cards {
    max-width: 580px;
    margin: auto;
    @include media-breakpoint-down(lg) {
      padding: 10px;
    }
    h2{
      @include media-breakpoint-down(lg) {
       font-size: 1rem!important;
      }
    }
    .card {
      box-shadow: 0 3px 6px #00000029;
      border-radius: 20px;
      margin-bottom: 20px;
      display: flex;
      flex-direction: row;
      padding: 20px 95px 20px 20px;
      font-size: 0.75rem;
      line-height: 1.2;
      position: relative;
      cursor: pointer;
      @include media-breakpoint-down(lg) {
        padding: 10px;
      }
      .right {
        width: 80%;
        padding-left: 20px;
        &:after {
          content: '';
          width: 23px;
          height: 15px;
          display: block;
          position: absolute;
          right: 40px;
          top: 47px;
          background: url(/build/assets/images/arrow_down.0bce112b.svg) no-repeat 50%;
          @include media-breakpoint-down(lg) {
            right: 10px;
            top: 10px;
          }
        }
        p {
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
      }
      .left {
        width: 20%;
        display: flex;
        justify-content: flex-end;
        align-items: baseline;
        img{
        @include media-breakpoint-down(lg) {
         width: 100%;
        }
        }
      }
      p {
        line-height: 1.2;
      }
      h2 {
        margin-top: 0 !important;
      }
      .active {
        &:after {
          background: url(/build/assets/images/arrow_up.071e8103.svg) no-repeat 50%;
        }
      }
      .right.active {
        p {
          display: block;
        }
      }
    }
  }
}
