/*********************************************
OVERRIDE DE HCOFFCANVAS
 */
.hc-offcanvas-nav .nav-close a:hover {
  color: #FFFFFF;
}

.hc-nav-trigger {
  right: 20px;
  position: fixed;
}

@keyframes border_anim {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

//@include media-breakpoint-down(lg) {
  .logo a {
    width: 300px;
    display: flex;
    align-items: center;
  }
  .logo a img {
    margin-right: 10px;
  }
//}

header {
  .logo .white {
    display: none;
  }

  #menu-site {
    height: 70px;
    display: flex;
    background: #ffffff;
    align-items: center;
    padding: 0 60px;
    justify-content: space-between;
    @include media-breakpoint-down(lg) {
      padding: 0 60px 0 10px;
    }

    ul {
      @include media-breakpoint-down(lg) {
        display: none;
      }

      a {
        color: #3E437E;
        position: relative;
        display: block;
      }

      list-style: none;
      display: flex;
      align-items: center;
      width: auto;
      padding: 0;
      margin: 0;
      text-align: center;

      li {
        margin-left: 90px;
        font-size: 19px;
        text-align: center;

        &:hover {
          a {
            text-decoration: none;

            &:after {
              content: '';
              display: block;
              height: 3px;
              background: #FD8B75;
              position: absolute;
              bottom: -5px;
              animation: border_anim .2s linear forwards;
            }
          }
        }
      }
    }
  }
}

#menu-site.menu-scroll {
  z-index: 99;
  box-shadow: 0 0 3px rgba(0, 0, 0, .5);

  .logo-menu img {
    width: 50%;
    margin: auto;
    text-align: center;
    display: block
  }

  a {
    color: #3E437E;
  }
}

#home_page {
  #menu-site {
    background: #3E437E;
    color: white !important;

    a {
      color: #ffffff;
    }

  }

  #menu-site.menu-scroll {
    a {
      color: #ffffff;
    }
  }

  .logo .blue {
    display: none;
  }

  .logo .white {
    display: block;
  }
}

.hc-offcanvas-nav .nav-container, .hc-offcanvas-nav .nav-wrapper, .hc-offcanvas-nav ul {
  background: #ffffff;
  border: none;
}

.hc-offcanvas-nav a, .hc-offcanvas-nav .nav-item {
  border: none;
  color: #FD8B75;
}

.hc-offcanvas-nav li.nav-close a, .hc-offcanvas-nav li.nav-back a {
  background: #ffffff;
  border: none;
  color: #FD8B75;
}

.hc-offcanvas-nav:not(.touch-device) a:hover {
  background: #FD8B75;
  color: white;

}

.hc-offcanvas-nav li.nav-close a:hover,
.hc-offcanvas-nav li.nav-back a:hover {
  color: black;
}

.hc-offcanvas-nav a:first-child, .hc-offcanvas-nav .nav-item:first-child {
  font-weight: bold;
}

.nav-close a {
  font-weight: normal !important;
  color: black !important;
}

.hc-offcanvas-nav li.nav-close span::before, .hc-offcanvas-nav li.nav-close span::after {
  border-color: black;
}

.hc-nav-trigger span,
.hc-nav-trigger span::before,
.hc-nav-trigger span::after {
  background: #3E437E;
}


#home_page .hc-nav-trigger span,
#home_page .hc-nav-trigger span::before,
#home_page .hc-nav-trigger span::after {
  background: white !important;
}

.hc-offcanvas-nav .nav-container {
  width: 100%;
}