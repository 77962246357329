$main_color: #0056be;
$main_color_lighter: #2b7df6;

$txt_color_light: #FFFFFF;
$txt_color_dark: #000000;

$errorColor: red;
$validColor: green;
$updated: yellow;

$font_size_base: calc(1em + 0.2vw);


//
//$grid-breakpoints: (
//        xs: 0,
//        sm: 576px,
//        md: 768px,
//        lg: 992px,
//        xl: 1200px
//) !default;


