
#realisation {
  padding-top: 60px;
  margin-bottom: 80px;
  .title {
    margin-bottom: 60px;
    h1{

      @include media-breakpoint-down(lg) {
        font-size: 1.5rem;
      }
    }
  }
  .cards {
    .realisation_card {
      display: flex !important;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      margin-bottom: 100px;
      @include media-breakpoint-down(lg) {
        flex-direction: column-reverse;
        margin-bottom: 50px;
      }
      .text {
        .title {
          margin-bottom: 10px;
          h3 {
            margin-bottom: 10px;
            font-size: 1.063rem;
            color: #3E437E;
            font-weight: bold;
          }
        }
        .description {
          font-size: 0.75rem;
        }
        .missions {
          font-size: 0.75rem;
          .title_mission {
            color: #3E437E;
            font-size: 0.875rem;
            font-weight: bold;
            margin: 10px 0;
          }
          .missions_details {
            ul {
              list-style: none;
              padding: 0;
            }
          }
        }
        .link {
          margin-top: 30px !important;
          text-align: center;
        }
        width: 40%;
        @include media-breakpoint-down(lg) {
          width: 100%;
          padding: 20px 10px;
        }
      }
      .image {
        img {
          width: 681px;
          height: auto;
          display: block;
          @include media-breakpoint-down(lg) {
            width: 100%;
          }
        }
        width: 60%;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
      @include media-breakpoint-up(md) {
        &:nth-child(even) {
          flex-direction: row-reverse;
          .image {
            img {
              margin-left: 0;
            }
          }
        }
        &:nth-child(odd) {
          .image {
            img {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
}