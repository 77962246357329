footer {
  .confiance {
    h2 {
      margin-bottom: 25px;
      font-size: 1.75rem;
    }
    .slick-list{
      height: 200px;
      @include media-breakpoint-down(lg) {
        height: 110px;

      }
    }
    .partner {
      width: 182px;
      height: 182px;
      display: flex;
      align-items: center;
      margin: 0 14px;
      border-radius: 20px;
      box-shadow: 0 3px 6px #00000029;
      padding: 10px;
      img {
        max-height: 100%;
        max-width: 100%;
        margin: auto;
      }
      @include media-breakpoint-down(lg) {
        width: 100px;
        height: 100px;
        margin: 0 5px;
      }
    }
  }
  .top_footer {
    .titre-big {
      font-weight: bold;
      font-size: 1.75rem;
      line-height: 1;
    }
    background: #3E437E;
    color: white;
    padding: 20px;
    .small {
      font-size: 0.75rem;
      line-height: 1;
    }
    h2 {
      line-height: 1;
      margin-bottom: 45px;
    }
    form {
      label {
        margin-top: 20px;
      }
      .checkbox {
        label {
          font-size: .85rem;
        }
      }
      input {
        border-radius: 18px;
        border: transparent;
      }
      textarea {
        border-radius: 18px;
        border: transparent;
        min-height: 180px;
      }
    }
    #contact_submit {
      margin: auto;
      display: block;
      margin-bottom: 20px;
    }
  }
  .sous-footer {
    background: #3E437E;
    padding: 40px;
    color: white;
    a {
      color: white;
      line-height: 2;
      @include media-breakpoint-down(lg) {
        font-size: 0.875rem!important;
      }
    }
  }
  .logo-white {
    a {
      span {
        font-size: 1.375rem;
        font-weight: bold;
        margin-left: 10px;
      }
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .contact {
    div {
      margin-top: 20px;
    }
  }

}
#contact_page input {
  border: #3E437E4D 1px solid;
}
#contact_page textarea {
  border: #3E437E4D 1px solid;
}
