/*********************************************
    IMPORT BOOTSRAP
 */
@import "~bootstrap/scss/bootstrap";
@import "~jquery-ui/themes/base/all.css";
@import "../js/hc-offcanvas/hc-offcanvas-nav.css";

/**********************************************************
IMPORT DES FICHIERS SCSS
 */

@import "variables";
@import "functions";
@import "reset";
@import "footer";
@import "components/utils";

@import "components/btn";
@import "components/login";
@import "components/title";
@import "components/alert";
@import "components/breadcrumb";


@import "forms/checkbox";
@import "forms/radio";
@import "forms/password";
@import "forms/datepicker";
@import "forms/error";
@import "forms/form";

@import "pages/home";
@import "pages/contact";
@import "pages/tarif";
@import "pages/produit";
@import "pages/documentation";
@import "pages/actuality";
@import "pages/testimonies";
@import "pages/services";
@import "pages/realisations";

/**********************************************************
OVERRIDES
 */

/**********************************************************
FONTS
 */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

    /**********************************************************
    GENERAL
     */
body {
  font-family: 'Montserrat', sans-serif;
  color:$txt_color_dark;

  /** A partir de **/
  @include media-breakpoint-up(xs) {

  }

  /** En dessous de **/
  @include media-breakpoint-down(xs) {

  }
  a{
    font-family: 'Montserrat', sans-serif;
  }
}
.salmon{
  color: #FD8B75 ;
}
.salmon-bg{
  background: #FD8B75;
}
.blue-dp{
  color: #3E437E;
}
.blue-dp-bg{
  background: #3E437E;
}


/**********************************************************
HEADER
 */
@import "header";

/**********************************************************
MENU
 */
@import "menu";

/**********************************************************
FORMULAIRE
 */

/*********************************************
    ETC...
 */
.trait_bottom{
  position: relative;
}
.trait_bottom:after{
  content: '';
  display: block;
  width: 10%;
  height: 2px;
  background: #71b8ab;
  position: absolute;
  left: 45%;
  bottom: -10px;
  @include media-breakpoint-down(lg) {
    width: 40%;
    left: 30%;
  }
}

.blue{
  color: #71b8ab;
}

#confidencialite,
#mention_legales{
  margin-top: 200px;
}

.mobile{
  display: none;
}


@include media-breakpoint-down(lg) {
  .mobile{
    display: unset;
  }
  .desktop{
    display: none;
  }
}