section>.content{
  max-width: 1280px;
  margin: auto
}
#home {
  .header {
    background: #3E437E;
    color: white;
    padding-top: 100px;
    padding-bottom: 50px;
    @include media-breakpoint-down(lg) {
      padding-top: 60px;
    }
    .content {
      padding: 60px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      @include media-breakpoint-down(lg) {
        padding: 10px;
        flex-direction: column-reverse;
      }
      .left {
        width: 45%;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
        h1 {
          font-size: 2.188rem;
          margin-bottom: 60px;
          @include media-breakpoint-down(lg) {
            font-size: 1.188rem;
            font-weight: normal;
          }
          .big {
            font-size: 3.35rem;
            @include media-breakpoint-down(lg) {
              font-size: 2rem;
            }
          }
        }
      }

      .mobile img{
        width: 100%;
        margin: 70px 0 20px;
      }
     .right{
        width: 55%;
        position: relative;
       @include media-breakpoint-down(lg) {
         width: 100%;
       }
       .rellax {
         &:nth-child(1) {
           position: relative;
         }
         position: absolute;
         &:nth-child(2) {
           width: 180px;
           bottom: -20px;
           right: -30px;
         }
         &:nth-child(3) {
           width: 180px;
           top: 52%;
           transform: translateY(-50%) !important;
         }
         &:nth-child(4) {
           width: 180px;
           top: 25%;
         }
         &:nth-child(5) {
           top: -10px;
           left: 62%;
           width: 110px;
         }
         &:nth-child(6) {
           width: 100px;
           bottom: -30px;
           left: 8%;
           z-index: 9;
         }
         &:nth-child(7) {
           width: 180px;
           top: 64%;
         }
         &:nth-child(8) {
           width: 40px;
           top: -45px;
           left: 45%;
         }
       }
      }

    }
  }
  .vagues {
    background: #3E437E;

    img {
      width: 100vw;
    }
  }


  .services {
    .content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 0 60px;
      @include media-breakpoint-down(lg) {
        padding: 0 10px;
      }
      .card {
        width: 29%;
        margin: 20px;
        box-shadow: 0 3px 6px #00000029;
        border-radius: 20px;
        padding: 30px 30px 45px;
        @include media-breakpoint-down(lg) {
          margin: 20px 0;
          width: 100%;
        }
        .icone{
          min-height: 90px
        }
        .text{
          font-size: 12px
        }
      }
    }
  }

  .team {
    border: solid;
    background: #FD8B75;
    color: white;
    justify-content: space-between;
    position: relative;
    .content {
      display: flex;
      flex-wrap: wrap;
      .left {
        position: relative;
        width: 40%;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
        img {
          position: absolute;
          left: 60%;
          top: 50%;
          transform: translate(-50%, -50%);
          @include media-breakpoint-down(lg) {
            position: relative;
            transform: translate(0,0);
            left: 0%;
            top: -10%;
          }
        }
      }
      .right {
        width: 60%;
        padding: 80px 60px;
        @include media-breakpoint-down(lg) {
          width: 100%;
          padding: 0 10px 20px;
        }
        .title {
          h2 {
            font-size: 28px;
          }
        }
        p {
          font-size: 13px;
        }
      }
    }
  }
  .realisations {
    .title {
      margin-bottom: 80px;
    }
    .cards {
      .realisation_card {
        display: flex !important;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 100px;
        @include media-breakpoint-down(lg) {
          flex-direction: column-reverse;
          margin-bottom: 50px;
        }
        .text {
          .title {
            margin-bottom: 10px;
            h3 {
              margin-bottom: 10px;
              font-size: 1.063rem;
              color: #3E437E;
              font-weight: bold;
            }
          }
          .description {
            font-size: 0.75rem;
          }
          .missions {
            font-size: 0.75rem;
            .title_mission {
              color: #3E437E;
              font-size: 0.875rem;
              font-weight: bold;
              margin: 10px 0;
            }
            .missions_details {
              ul {
                list-style: none;
                padding: 0;
              }
            }
          }
          .link {
            margin-top: 30px !important;
            text-align: center;
          }
          width: 40%;
          @include media-breakpoint-down(lg) {
            width: 100%;
            padding: 20px 10px;
          }
        }
        .image {
          img {
            width: 681px;
            height: auto;
            display: block;
            @include media-breakpoint-down(lg) {
              width: 100%;
            }
          }
          width: 60%;
          @include media-breakpoint-down(lg) {
            width: 100%;
          }
        }
        @include media-breakpoint-up(md) {
        &:nth-child(even) {
          flex-direction: row-reverse;
          .image {
            img {
              margin-left: 0;
            }
          }
        }
        &:nth-child(odd) {
          .image {
            img {
              margin-left: auto;
            }
          }
          }
        }
      }
    }
  }

}